import { withAuthenticator } from '@aws-amplify/ui-react'
import { Filter, FilterBox } from '@imlearningteam/imlearning-types'
import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import { Footer } from '../../components/footer'
import { Header } from '../../components/header'
import { Sidebar } from '../../components/sidebar'
import { Table } from '../../components/table'
import { deleteData, getData } from '../../utils/server'
import AddFilterBoxModal from './addFilterBoxModal'

function FilterBoxManager() {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [modalFilterBox, setModalFilterBox] = useState<FilterBox>()
  const [filterBoxes, setFilterBoxes] = useState<any[]>([])
  const [filters, setFilters] = useState<Filter[]>([])

  useEffect(() => {
    if (!success && !error) {
      getData('/filterbox').then(
        (result: any) => {
          setSuccess(true)
          setFilterBoxes(result)
        },
        (error: any) => {
          setError(error)
        },
      )

      getData('/filter').then((result: any) => {
        setFilters(result)
      })
    }
  })

  function put_filter_box(filterBox: FilterBox) {
    if (filterBoxes.some((x) => x.id === filterBox.id)) {
      let item = filterBoxes.findIndex((x) => x.id === filterBox.id)
      filterBoxes[item] = filterBox
    } else {
      setFilterBoxes([...filterBoxes, filterBox])
    }
  }

  function tog_backdrop(item?: FilterBox) {
    setModalFilterBox(undefined)
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()

    if (item) {
      setModalFilterBox(item)
    }
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }

  function delete_item(id: string) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this Filter Box?') === true) {
      deleteData('/filterbox', id).then(
        (result: any) => {
          setFilterBoxes(
            filterBoxes.filter(function (filterBox) {
              return filterBox.id !== id
            }),
          )
        },
        (error: any) => {
          setError(error)
        },
      )
    }
  }

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div className="main-content">
          <div className="page-content">
            <h4 className="font-size-18">Filter Boxes</h4>
            <button
              type="button"
              className="btn btn-success float-end"
              onClick={() => {
                tog_backdrop()
              }}
              data-toggle="modal"
              style={{ marginRight: '50px' }}
            >
              Add New
            </button>
            <Modal
              size="lg"
              isOpen={modal_backdrop}
              toggle={() => {
                tog_backdrop()
              }}
              backdrop={'static'}
              id="staticBackdrop"
            >
              <AddFilterBoxModal
                setmodal_backdrop={setmodal_backdrop}
                put_filter_box={put_filter_box}
                filterBox={modalFilterBox}
                filters={filters}
              />
            </Modal>
            <Table columns={['Name', 'Tags']}>
              {filterBoxes.map((item: FilterBox, count) => {
                return (
                  <tr key={count}>
                    <td>{item.name}</td>
                    <td>
                      {item.filters.map((filter, index) => {
                        return index === 0
                          ? `${filter.tag.name}`
                          : `, ${filter.tag.name}`
                      })}
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          tog_backdrop(item)
                        }}
                        data-toggle="modal"
                      >
                        Update
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-light btn-sm fa-pull-right"
                        onClick={() => {
                          delete_item(item.id)
                        }}
                        data-toggle="modal"
                      >
                        <i className="bx bx-x"></i>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </Table>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export const FilterBoxes = withAuthenticator(FilterBoxManager, {
  hideSignUp: true,
  loginMechanisms: ['email'],
})
