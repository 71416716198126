export function getVideoIdFromUrl(url: string | undefined) {
  return url ? url.replace('https://www.youtube.com/embed/', '') : ''
}

export function createVideoEmbedUrl(id: string) {
  return `https://www.youtube.com/embed/${id}`
}

export function createVideoImageUrl(id: string) {
  return `https://i.ytimg.com/vi/${id}/maxresdefault.jpg`
}

export function createImageAltText(title: string, addedText: string) {
  return `${title} ${addedText}`
}
