import { Amplify, Auth } from 'aws-amplify'
import React from 'react'
import ReactDOM from 'react-dom/client'
import * as router from 'react-router-dom'
import './index.css'
import './assets/scss/theme.scss'
import './assets/scss/preloader.scss'
import { Home } from './page/home'
import reportWebVitals from './reportWebVitals'
import { Tutorials } from './page/tutorials'
import { Blogs } from './page/blogs'
import { Videos } from './page/videos'
import { Tags } from './page/tags'
import { Filters } from './page/filters'
import { FilterBoxes } from './page/filterbox'
import { Roadmaps } from './page/roadmaps'

Amplify.configure({
  Auth: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_0uoLbuGLe',
    userPoolWebClientId: '4br0so2tauhr0dsn6ftdg38ml8',
    mandatorySignIn: false,
  },
})

Auth.configure()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLDivElement,
)

root.render(
  <React.StrictMode>
    <router.BrowserRouter>
      <router.Routes>
        <router.Route path="/" element={<Home />} />
        <router.Route path="/tutorials" element={<Tutorials />} />
        <router.Route path="/blogs" element={<Blogs />} />
        <router.Route path="/videos" element={<Videos />} />
        <router.Route path="/roadmaps" element={<Roadmaps />} />
        <router.Route path="/tags" element={<Tags />} />
        <router.Route path="/filters" element={<Filters />} />
        <router.Route path="/filter-boxes" element={<FilterBoxes />} />
      </router.Routes>
    </router.BrowserRouter>
  </React.StrictMode>,
)

reportWebVitals()
