import { withAuthenticator } from '@aws-amplify/ui-react'
import React, { useEffect, useState } from 'react'
import { Roadmap } from '@imlearningteam/imlearning-types'
import { deleteData, getData } from '../../utils/server'
import { Footer } from '../../components/footer'
import { Header } from '../../components/header'
import { Sidebar } from '../../components/sidebar'
import { Table } from '../../components/table'
import { Modal } from 'reactstrap'
import AddRoadmapModal from './addRoadmapModal'
import { daysUntilReview } from '../../utils/resources'

function RoadmapPage() {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [modalRoadmap, setModalRoadmap] = useState<Roadmap>()
  const [roadmaps, setRoadmaps] = useState<any[]>([])

  useEffect(() => {
    if (!success && !error) {
      getData('/roadmap').then(
        (result: any) => {
          setSuccess(true)
          setRoadmaps(result)
        },
        (error: any) => {
          setError(error)
        },
      )
    }
  })

  function tog_backdrop(item?: Roadmap) {
    setModalRoadmap(undefined)
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()

    if (item) {
      setModalRoadmap(item)
    }
  }

  function put_roadmap_post(roadmap: Roadmap) {
    if (roadmaps.some((x) => x.id === roadmap.id)) {
      let item = roadmaps.findIndex((x) => x.id === roadmap.id)
      roadmaps[item] = roadmap
    } else {
      setRoadmaps([...roadmaps, roadmap])
    }
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }

  function delete_item(id: string) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this Roadmap?') === true) {
      deleteData('/roadmap', id).then(
        (result: any) => {
          setRoadmaps(
            roadmaps.filter(function (roadmap) {
              return roadmap.id !== id
            }),
          )
        },
        (error: any) => {
          setError(error)
        },
      )
    }
  }

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div className="main-content">
          <div className="page-content">
            <h4 className="font-size-18">Roadmaps</h4>
            <button
              type="button"
              className="btn btn-success float-end"
              onClick={() => {
                tog_backdrop()
              }}
              data-toggle="modal"
              style={{ marginRight: '50px' }}
            >
              Add New
            </button>
            <Modal
              size="lg"
              isOpen={modal_backdrop}
              toggle={() => {
                tog_backdrop()
              }}
              backdrop={'static'}
              id="staticBackdrop"
            >
              <AddRoadmapModal
                setmodal_backdrop={setmodal_backdrop}
                put_roadmap_post={put_roadmap_post}
                roadmap={modalRoadmap}
              />
            </Modal>
            <Table columns={['Title', 'Price']}>
              {roadmaps.map((item: Roadmap, count) => {
                return (
                  <tr
                    key={count}
                    className={
                      daysUntilReview(new Date(item.lastUpdated)) < 10
                        ? 'bg-danger'
                        : ''
                    }
                  >
                    <td>{item.title}</td>
                    <td>{item.price}</td>
                    <td>{daysUntilReview(new Date(item.lastUpdated))} days</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          tog_backdrop(item)
                        }}
                        data-toggle="modal"
                      >
                        Update
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-light btn-sm"
                        onClick={() => {
                          delete_item(item.id)
                        }}
                        data-toggle="modal"
                      >
                        <i className="bx bx-x"></i>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </Table>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export const Roadmaps = withAuthenticator(RoadmapPage, {
  hideSignUp: true,
  loginMechanisms: ['email'],
})
