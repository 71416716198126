import { Icon } from '@ailibs/feather-react-ts'
import React from 'react'

interface SocialButtonProps {
  text: string
  url: string
  hashtags: string
}

export const SocialButton = (props: SocialButtonProps) => {
  if (process.env.REACT_APP_ENVIRONMENT === 'dev') return null
  return (
    <a
      href={`https://twitter.com/intent/tweet?text=${props.text}&url=${props.url}&hashtags=${props.hashtags}`}
      target="_blank"
      rel="noreferrer"
    >
      <Icon name="twitter" className="icon-lg" />
    </a>
  )
}
