import React, { useState } from 'react'
import { Col, Input, Label, Row } from 'reactstrap'
import { Filter, FilterType, Tag } from '@imlearningteam/imlearning-types'
import { putData } from '../../utils/server'
import { v4 } from 'uuid'

export default function AddFilterModal(props: {
  setmodal_backdrop: React.Dispatch<React.SetStateAction<boolean>>
  put_filter: Function
  filter?: Filter
  tags: Tag[]
}) {
  const [selectedTag, setSelectedTag] = useState<Tag>()
  const [selectedFilterType, setSelectedFilterType] = useState<FilterType>()

  if (props.filter && !selectedTag && !selectedFilterType) {
    setSelectedTag(props.filter.tag)
    setSelectedFilterType(props.filter.type)
  }

  function handleSubmit(event: any) {
    event.preventDefault()

    const data: Filter = {
      id: props.filter?.id ?? v4(),
      name: event.target.elements.name.value,
      tag: selectedTag!,
      type: selectedFilterType!,
    }

    putData('/filter', data).then(
      () => {
        props.put_filter(data)
        props.setmodal_backdrop(false)
      },
      (error) => {
        console.log(error)
      },
    )
  }

  function tag_selected(e: any) {
    let { value } = e.target
    const tag: Tag = props.tags.find((x: Tag) => x.name === value)!
    setSelectedTag(tag)
  }

  function filter_type_selected(e: any) {
    let { value } = e.target
    setSelectedFilterType(FilterType[value as keyof typeof FilterType])
  }

  return (
    <div className="m-4">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Add Filter
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.setmodal_backdrop(false)
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <form className="form" onSubmit={handleSubmit}>
          <Row className="mb-4">
            <Label htmlFor="name" className="col-sm-3 col-form-label">
              Name:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="name"
                name="name"
                defaultValue={props.filter?.name}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="name" className="col-sm-3 col-form-label">
              Tag:
            </Label>
            <Col sm={9}>
              <Input
                type="select"
                className="form-control"
                id="tag"
                name="tag"
                defaultValue={props.filter?.tag.name}
                onChange={tag_selected}
              >
                {props.tags.map((item: Tag) => {
                  return (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  )
                })}
              </Input>
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="name" className="col-sm-3 col-form-label">
              Filter Type:
            </Label>
            <Col sm={9}>
              <Input
                type="select"
                className="form-control"
                id="filterType"
                name="filterType"
                defaultValue={props.filter?.type}
                onChange={filter_type_selected}
              >
                {Object.keys(FilterType).map((key) => {
                  return (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  )
                })}
              </Input>
            </Col>
          </Row>
          <div className="modal-footer">
            <input type="submit" value="Submit" className="btn btn-primary" />
          </div>
        </form>
      </div>
    </div>
  )
}
