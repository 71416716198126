import PropTypes from 'prop-types'
import React, { useEffect, useRef, useCallback } from 'react'
import { Icon } from '@ailibs/feather-react-ts'
import SimpleBar from 'simplebar-react'
import MetisMenu from 'metismenujs'
import { Link } from 'react-router-dom'

export const SidebarContent = (props: any) => {
  const ref = useRef<any>()

  const activateParentDropdown = useCallback(
    (item: {
      classList: { add: (arg0: string) => void }
      parentElement: any
    }) => {
      item.classList.add('active')
      const parent = item.parentElement
      const parent2El = parent.childNodes[1]
      if (parent2El && parent2El.id !== 'side-menu') {
        parent2El.classList.add('mm-show')
      }

      if (parent) {
        parent.classList.add('mm-active')
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.add('mm-show') // ul tag

          const parent3 = parent2.parentElement // li tag

          if (parent3) {
            parent3.classList.add('mm-active') // li
            parent3.childNodes[0].classList.add('mm-active') //a
            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.add('mm-show') // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.add('mm-show') // li
                parent5.childNodes[0].classList.add('mm-active') // a tag
              }
            }
          }
        }
        scrollElement(item)
        return false
      }
      scrollElement(item)
      return false
    },
    [],
  )

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu('#side-menu')
      let matchingMenuItem = null
      const ul: any = document.getElementById('side-menu')
      const items = ul.getElementsByTagName('a')
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }
  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Menu</li>
            <li>
              <Link to="/">
                <Icon name="home" />
                <span>Home</span>
              </Link>
            </li>
            <li className="menu-title">Resources</li>
            <li>
              <Link to="/blogs">
                <Icon name="file-text" />
                <span>Blog Posts</span>
              </Link>
            </li>
            <li>
              <Link to="/tutorials">
                <Icon name="film" />
                <span>Tutorials</span>
              </Link>
            </li>
            <li>
              <Link to="/videos">
                <Icon name="youtube" />
                <span>Videos</span>
              </Link>
            </li>
            <li>
              <Link to="/roadmaps">
                <Icon name="layers" />
                <span>Roadmaps</span>
              </Link>
            </li>
            <li className="menu-title">Discoverability</li>
            <li>
              <Link to="/tags">
                <Icon name="tag" />
                <span>Tags</span>
              </Link>
            </li>
            <li>
              <Link to="/filters">
                <Icon name="filter" />
                <span>Filters</span>
              </Link>
            </li>
            <li>
              <Link to="/filter-boxes">
                <Icon name="box" />
                <span>Filter Boxes</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
