import { Roadmap, Tag } from '@imlearningteam/imlearning-types'
import React, { useEffect, useState } from 'react'
import { Col, Input, Label, Row } from 'reactstrap'
import { v4 } from 'uuid'
import { getData, putData } from '../../utils/server'
import { MultiSelect } from 'react-multi-select-component'
import { createImageAltText } from '../helper'
import { createSlugFromTitle } from '../../utils/resources'

type Option = {
  value: string
  label: string
}

export default function AddRoadmapModal(props: {
  setmodal_backdrop: React.Dispatch<React.SetStateAction<boolean>>
  put_roadmap_post: Function
  roadmap?: Roadmap
}) {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [tags, setTags] = useState<Option[]>([])
  const [selectedTags, setSelectedTags] = useState<Option[]>([])

  useEffect(() => {
    if (!success && !error) {
      getData('/tag').then(
        (result: any) => {
          const theTags = result.map((tag: Tag) => {
            return { label: tag.name, value: tag.name }
          })
          setTags(theTags)
          setSelectedTags(
            props.roadmap?.tags.map((item) => {
              return { label: item, value: item }
            }) ?? [],
          )
          setSuccess(true)
        },
        (error: any) => {
          setError(error)
        },
      )
    }
  })

  function handleRoadmapSubmit(event: any) {
    event.preventDefault()

    const data: Roadmap = {
      id: props.roadmap?.id ?? v4(),
      external: false,
      mainImage: {
        src: event.target.elements.mainImageSrc.value,
        alt: createImageAltText(
          event.target.elements.title.value,
          'Roadmap thumbnail image',
        ),
      },
      title: event.target.elements.title.value,
      description: event.target.elements.description.value,
      tags:
        selectedTags?.map((item) => {
          return item.value
        }) ?? [],
      lastUpdated: new Date(),
      publishDate: props.roadmap?.publishDate ?? new Date(),
      slug: createSlugFromTitle(event.target.elements.title.value),
      paymentLink: event.target.elements.paymentLink.value,
      price: event.target.elements.price.value,
    }

    putData('/roadmap', data).then(
      () => {
        props.put_roadmap_post(data)
        props.setmodal_backdrop(false)
      },
      (error) => {
        console.log(error)
      },
    )
  }

  function tags_changed(value: Option[]) {
    setSelectedTags(value)
  }

  return (
    <div className="m-4">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Add Roadmap
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.setmodal_backdrop(false)
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <form className="form" onSubmit={handleRoadmapSubmit}>
          <Row className="mb-4">
            <Label htmlFor="mainImageSrc" className="col-sm-3 col-form-label">
              Main Image Url:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="mainImageSrc"
                name="mainImageSrc"
                required
                defaultValue={props.roadmap?.mainImage.src}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="title" className="col-sm-3 col-form-label">
              Title:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="title"
                name="title"
                required
                defaultValue={props.roadmap?.title}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="description" className="col-sm-3 col-form-label">
              Description:
            </Label>
            <Col sm={9}>
              <Input
                type="textarea"
                className="form-control"
                id="description"
                name="description"
                maxLength={160}
                rows={5}
                required
                defaultValue={props.roadmap?.description}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="paymentLink" className="col-sm-3 col-form-label">
              Payment Link:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="paymentLink"
                name="paymentLink"
                required
                defaultValue={props.roadmap?.paymentLink}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="price" className="col-sm-3 col-form-label">
              Price:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="price"
                name="price"
                required
                defaultValue={props.roadmap?.price}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="tag" className="col-sm-3 col-form-label">
              Tags:
            </Label>
            <Col sm={9}>
              <MultiSelect
                options={tags}
                value={selectedTags}
                onChange={tags_changed}
                labelledBy="tags"
              />
            </Col>
          </Row>
          <div className="modal-footer">
            <input type="submit" value="Submit" className="btn btn-primary" />
          </div>
        </form>
      </div>
    </div>
  )
}
