import { Auth } from 'aws-amplify'

export async function putData(path = '', data = {}) {
  const session = await Auth.currentSession()
  const jwt = session.getIdToken().getJwtToken()

  const response = await fetch(`${process.env.REACT_APP_CMS_API_URL}${path}`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: jwt,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  })
  return response.json()
}

export async function getData(path = '') {
  const session = await Auth.currentSession()
  const jwt = session.getIdToken().getJwtToken()

  const response = await fetch(`${process.env.REACT_APP_CMS_API_URL}${path}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: jwt,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  })
  return response.json()
}

export async function deleteData(path: string, id: string) {
  const session = await Auth.currentSession()
  const jwt = session.getIdToken().getJwtToken()

  const response = await fetch(`${process.env.REACT_APP_CMS_API_URL}${path}`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: jwt,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({ id: id }),
  })
  return response.json()
}
