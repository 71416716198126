import { withAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import React from 'react'
import { Footer } from '../../components/footer'
import { Header } from '../../components/header'
import { Sidebar } from '../../components/sidebar'

function home() {
  return (
    <div>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <Header />
        <Sidebar
        // theme={leftSideBarTheme}
        // type={leftSideBarType}
        // isMobile={isMobile}
        />
        <div className="main-content">
          <div className="page-content">
            <h4 className="font-size-18">Welcome back!</h4>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export const Home = withAuthenticator(home, {
  hideSignUp: true,
  loginMechanisms: ['email'],
})
