import React, { useState } from 'react'
import { Col, Input, Label, Row } from 'reactstrap'
import { Filter, FilterBox } from '@imlearningteam/imlearning-types'
import { putData } from '../../utils/server'
import { v4 } from 'uuid'
import { MultiSelect } from 'react-multi-select-component'

type Option = {
  value: string
  label: string
}

export default function AddFilterBoxModal(props: {
  setmodal_backdrop: React.Dispatch<React.SetStateAction<boolean>>
  put_filter_box: Function
  filterBox?: FilterBox
  filters: Filter[]
}) {
  const [selectedFilters, setSelectedFilters] = useState<Option[]>([])
  const [filterOptions, setFilterOptions] = useState<Option[]>([])

  if (filterOptions.length === 0) {
    const filters = props.filters.map((filter) => {
      return { label: filter.name, value: filter.id }
    })

    setFilterOptions(filters)
  }

  if (props.filterBox && selectedFilters.length === 0) {
    const selectedItems = props.filterBox.filters.map((filter) => {
      return { label: filter.name, value: filter.id }
    })

    setSelectedFilters(selectedItems)
  }

  function filters_changed(value: Option[]) {
    setSelectedFilters(value)
  }

  function get_filters_from_options(options: Option[]): Filter[] {
    const data = options.map((option): Filter | undefined => {
      return props.filters.find((filter) => option.value === filter.id)
    })

    const isFilter = (item: Filter | undefined): item is Filter => {
      return !!item
    }

    const filteredData = data.filter(isFilter)

    return filteredData
  }

  function handleSubmit(event: any) {
    event.preventDefault()

    const filters = get_filters_from_options(selectedFilters)

    const data: FilterBox = {
      id: props.filterBox?.id ?? v4(),
      name: event.target.elements.name.value,
      filters: filters,
    }

    putData('/filterbox', data).then(
      () => {
        props.put_filter_box(data)
        props.setmodal_backdrop(false)
      },
      (error) => {
        console.log(error)
      },
    )
  }

  return (
    <div className="m-4">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Add Filter Box
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.setmodal_backdrop(false)
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <form className="form" onSubmit={handleSubmit}>
          <Row className="mb-4">
            <Label htmlFor="name" className="col-sm-3 col-form-label">
              Name:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="name"
                name="name"
                defaultValue={props.filterBox?.name}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="name" className="col-sm-3 col-form-label">
              Filters:
            </Label>
            <Col sm={9}>
              <MultiSelect
                options={filterOptions}
                value={selectedFilters}
                onChange={filters_changed}
                labelledBy="filters"
              />
            </Col>
          </Row>
          <div className="modal-footer">
            <input type="submit" value="Submit" className="btn btn-primary" />
          </div>
        </form>
      </div>
    </div>
  )
}
