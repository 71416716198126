import { Tag, Video } from '@imlearningteam/imlearning-types'
import React, { useEffect, useState } from 'react'
import { MultiSelect } from 'react-multi-select-component'
import { Col, Input, Label, Row } from 'reactstrap'
import { v4 } from 'uuid'
import { createSlugFromTitle } from '../../utils/resources'
import { getData, putData } from '../../utils/server'
import {
  createImageAltText,
  createVideoEmbedUrl,
  createVideoImageUrl,
  getVideoIdFromUrl,
} from '../helper'

type Option = {
  value: string
  label: string
}

export default function AddVideoModal(props: {
  setmodal_backdrop: React.Dispatch<React.SetStateAction<boolean>>
  put_video: Function
  video?: Video
}) {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [tags, setTags] = useState<Option[]>([])
  const [selectedTags, setSelectedTags] = useState<Option[]>([])

  useEffect(() => {
    if (!success && !error) {
      getData('/tag').then(
        (result: any) => {
          const theTags = result.map((tag: Tag) => {
            return { label: tag.name, value: tag.name }
          })
          setTags(theTags)
          setSelectedTags(
            props.video?.tags.map((item) => {
              return { label: item, value: item }
            }) ?? [],
          )
          setSuccess(true)
        },
        (error: any) => {
          setError(error)
        },
      )
    }
  })

  function handleVideoSubmit(event: any) {
    event.preventDefault()

    const data: Video = {
      id: props.video?.id ?? v4(),
      link: {
        href: createVideoEmbedUrl(event.target.elements.videoId.value),
        target: '_blank',
      },
      mainImage: {
        src: createVideoImageUrl(event.target.elements.videoId.value),
        alt: createImageAltText(
          event.target.elements.title.value,
          'video thumbnail image',
        ),
      },
      title: event.target.elements.title.value,
      description: event.target.elements.description.value,
      channel: event.target.elements.channel.value,
      tags:
        selectedTags?.map((item) => {
          return item.value
        }) ?? [],
      external: true,
      lastUpdated: new Date(),
      publishDate: props.video?.publishDate ?? new Date(),
      slug: createSlugFromTitle(event.target.elements.title.value),
    }

    putData('/video', data).then(
      () => {
        props.put_video(data)
        props.setmodal_backdrop(false)
      },
      (error) => {
        console.log(error)
      },
    )
  }

  function tags_changed(value: Option[]) {
    setSelectedTags(value)
  }

  return (
    <div className="m-4">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Add External Video
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.setmodal_backdrop(false)
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <form className="form" onSubmit={handleVideoSubmit}>
          <Row className="mb-4">
            <Label htmlFor="videoId" className="col-sm-3 col-form-label">
              Video ID:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="videoId"
                name="videoId"
                required
                defaultValue={getVideoIdFromUrl(props.video?.link?.href)}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="title" className="col-sm-3 col-form-label">
              Title:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="title"
                name="title"
                required
                defaultValue={props.video?.title}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="description" className="col-sm-3 col-form-label">
              Description:
            </Label>
            <Col sm={9}>
              <Input
                type="textarea"
                className="form-control"
                id="description"
                name="description"
                maxLength={160}
                rows={5}
                required
                defaultValue={props.video?.description}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="channel" className="col-sm-3 col-form-label">
              Channel Name:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="channel"
                name="channel"
                defaultValue={props.video?.channel}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="tag" className="col-sm-3 col-form-label">
              Tag:
            </Label>
            <Col sm={9}>
              <MultiSelect
                options={tags}
                value={selectedTags}
                onChange={tags_changed}
                labelledBy="tags"
              />
            </Col>
          </Row>
          <div className="modal-footer">
            <input type="submit" value="Submit" className="btn btn-primary" />
          </div>
        </form>
      </div>
    </div>
  )
}
