import { withAuthenticator } from '@aws-amplify/ui-react'
import { Tag } from '@imlearningteam/imlearning-types'
import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import { Table } from '../../components/table'
import { Footer } from '../../components/footer'
import { Header } from '../../components/header'
import { Sidebar } from '../../components/sidebar'
import { deleteData, getData } from '../../utils/server'
import AddTagModal from './addTagModal'

function TagManager() {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [modalTag, setModalTag] = useState<Tag>()
  const [tags, setTags] = useState<any[]>([])

  useEffect(() => {
    if (!success && !error) {
      getData('/tag').then(
        (result: any) => {
          setSuccess(true)
          setTags(result)
        },
        (error: any) => {
          setError(error)
        },
      )
    }
  })

  function put_tag(tag: Tag) {
    if (tags.some((x) => x.name === tag.name)) {
      let item = tags.findIndex((x) => x.name === tag.name)
      tags[item] = tag
    } else {
      setTags([...tags, tag])
    }
  }

  function tog_backdrop(item?: Tag) {
    setModalTag(undefined)
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()

    if (item) {
      setModalTag(item)
    }
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }

  function delete_item(id: string) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this tag?') === true) {
      deleteData('/tag', id).then(
        (result: any) => {
          setTags(
            tags.filter(function (blog) {
              return blog.id !== id
            }),
          )
        },
        (error: any) => {
          setError(error)
        },
      )
    }
  }

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div className="main-content">
          <div className="page-content">
            <h4 className="font-size-18">Tags</h4>
            <button
              type="button"
              className="btn btn-success float-end"
              onClick={() => {
                tog_backdrop()
              }}
              data-toggle="modal"
              style={{ marginRight: '50px' }}
            >
              Add New
            </button>
            <Modal
              size="lg"
              isOpen={modal_backdrop}
              toggle={() => {
                tog_backdrop()
              }}
              backdrop={'static'}
              id="staticBackdrop"
            >
              <AddTagModal
                setmodal_backdrop={setmodal_backdrop}
                put_tag={put_tag}
                tag={modalTag}
              />
            </Modal>
            <Table columns={['Name']}>
              {tags.map((item: Tag, count) => {
                return (
                  <tr key={count}>
                    <td>{item.name}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-light btn-sm fa-pull-right"
                        onClick={() => {
                          delete_item(item.id)
                        }}
                        data-toggle="modal"
                      >
                        <i className="bx bx-x"></i>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </Table>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export const Tags = withAuthenticator(TagManager, {
  hideSignUp: true,
  loginMechanisms: ['email'],
})
