import { withAuthenticator } from '@aws-amplify/ui-react'
import { Filter, Tag } from '@imlearningteam/imlearning-types'
import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import { Table } from '../../components/table'
import { Footer } from '../../components/footer'
import { Header } from '../../components/header'
import { Sidebar } from '../../components/sidebar'
import { deleteData, getData } from '../../utils/server'
import AddFilterModal from './addFilterModal'

function FilterManager() {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [modalFilter, setModalFilter] = useState<Filter>()
  const [filters, setFilters] = useState<any[]>([])
  const [tags, setTags] = useState<Tag[]>([])

  useEffect(() => {
    if (!success && !error) {
      getData('/filter').then(
        (result: any) => {
          setSuccess(true)
          setFilters(result)
        },
        (error: any) => {
          setError(error)
        },
      )

      getData('/tag').then((result: any) => {
        setTags(result)
      })
    }
  })

  function put_filter(filter: Filter) {
    if (filters.some((x) => x.id === filter.id)) {
      let item = filters.findIndex((x) => x.id === filter.id)
      filters[item] = filter
    } else {
      setFilters([...filters, filter])
    }
  }

  function tog_backdrop(item?: Filter) {
    setModalFilter(undefined)
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()

    if (item) {
      setModalFilter(item)
    }
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }

  function delete_item(id: string) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this filter?') === true) {
      deleteData('/filter', id).then(
        (result: any) => {
          setFilters(
            filters.filter(function (filter) {
              return filter.id !== id
            }),
          )
        },
        (error: any) => {
          setError(error)
        },
      )
    }
  }

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div className="main-content">
          <div className="page-content">
            <h4 className="font-size-18">Filters</h4>
            <button
              type="button"
              className="btn btn-success float-end"
              onClick={() => {
                tog_backdrop()
              }}
              data-toggle="modal"
              style={{ marginRight: '50px' }}
            >
              Add New
            </button>
            <Modal
              size="lg"
              isOpen={modal_backdrop}
              toggle={() => {
                tog_backdrop()
              }}
              backdrop={'static'}
              id="staticBackdrop"
            >
              <AddFilterModal
                setmodal_backdrop={setmodal_backdrop}
                put_filter={put_filter}
                filter={modalFilter}
                tags={tags}
              />
            </Modal>
            <Table columns={['Tag', 'Name', 'Type']}>
              {filters.map((item: Filter, count) => {
                return (
                  <tr key={count}>
                    <td>{item.tag.name}</td>
                    <td>{item.name}</td>
                    <td>{item.type}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          tog_backdrop(item)
                        }}
                        data-toggle="modal"
                      >
                        Update
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-light btn-sm fa-pull-right"
                        onClick={() => {
                          delete_item(item.id)
                        }}
                        data-toggle="modal"
                      >
                        <i className="bx bx-x"></i>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </Table>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export const Filters = withAuthenticator(FilterManager, {
  hideSignUp: true,
  loginMechanisms: ['email'],
})
