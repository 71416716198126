import { Blog, Tag } from '@imlearningteam/imlearning-types'
import React, { useEffect, useState } from 'react'
import { Col, Input, Label, Row } from 'reactstrap'
import { v4 } from 'uuid'
import { Editor } from '../../components/rte/Editor'
import { getData, putData } from '../../utils/server'
import { MultiSelect } from 'react-multi-select-component'
import { createImageAltText } from '../helper'
import { createSlugFromTitle } from '../../utils/resources'

type Option = {
  value: string
  label: string
}

export default function AddBlogModal(props: {
  setmodal_backdrop: React.Dispatch<React.SetStateAction<boolean>>
  put_blog_post: Function
  blog?: Blog
}) {
  const [editorValue, setEditorValue] = useState(props.blog?.content ?? '')
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [tags, setTags] = useState<Option[]>([])
  const [selectedTags, setSelectedTags] = useState<Option[]>([])

  useEffect(() => {
    if (!success && !error) {
      getData('/tag').then(
        (result: any) => {
          const theTags = result.map((tag: Tag) => {
            return { label: tag.name, value: tag.name }
          })
          setTags(theTags)
          setSelectedTags(
            props.blog?.tags.map((item) => {
              return { label: item, value: item }
            }) ?? [],
          )
          setSuccess(true)
        },
        (error: any) => {
          setError(error)
        },
      )
    }
  })

  function handleBlogSubmit(event: any) {
    event.preventDefault()

    const data: Blog = {
      id: props.blog?.id ?? v4(),
      external: event.target.elements.external.checked,
      link: {
        href: event.target.elements.linkHref.value,
        target: event.target.elements.external.checked ? '_blank' : '',
      },
      mainImage: {
        src: event.target.elements.mainImageSrc.value,
        alt: createImageAltText(
          event.target.elements.title.value,
          'blog thumbnail image',
        ),
      },
      author: {
        link: {
          href: event.target.elements.authorLinkHref.value,
          target: '_blank',
        },
        name: event.target.elements.authorName.value,
      },
      title: event.target.elements.title.value,
      description: event.target.elements.description.value,
      content: editorValue,
      tags:
        selectedTags?.map((item) => {
          return item.value
        }) ?? [],
      lastUpdated: new Date(),
      publishDate: props.blog?.publishDate ?? new Date(),
      slug: createSlugFromTitle(event.target.elements.title.value),
    }

    putData('/blog', data).then(
      () => {
        props.put_blog_post(data)
        props.setmodal_backdrop(false)
      },
      (error) => {
        console.log(error)
      },
    )
  }

  function updateLinkRefRequired(linkRequired: boolean) {
    ;(document.getElementById('linkHref')! as HTMLInputElement).required =
      linkRequired
  }

  function editor_updated(value: string) {
    setEditorValue(value)
  }

  function tags_changed(value: Option[]) {
    setSelectedTags(value)
  }

  return (
    <div className="m-4">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Add Blog
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.setmodal_backdrop(false)
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <form className="form" onSubmit={handleBlogSubmit}>
          <Row className="mb-4">
            <Label htmlFor="linkHref" className="col-sm-3 col-form-label">
              External Resource:
            </Label>
            <Col sm={9}>
              <Input
                type="checkbox"
                id="external"
                name="external"
                defaultChecked={props.blog?.external}
                onChange={(e) => updateLinkRefRequired(e.target.checked)}
                style={{ marginTop: '10px', height: '18px', width: '18px' }}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="linkHref" className="col-sm-3 col-form-label">
              Link to Blog:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="linkHref"
                name="linkHref"
                defaultValue={props.blog?.link?.href}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="mainImageSrc" className="col-sm-3 col-form-label">
              Main Image Url:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="mainImageSrc"
                name="mainImageSrc"
                required
                defaultValue={props.blog?.mainImage.src}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="authorLinkHref" className="col-sm-3 col-form-label">
              Author Link Url:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="authorLinkHref"
                name="authorLinkHref"
                required
                defaultValue={props.blog?.author.link.href}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="authorName" className="col-sm-3 col-form-label">
              Author Name:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="authorName"
                name="authorName"
                defaultValue={props.blog?.author.name}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="title" className="col-sm-3 col-form-label">
              Title:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="title"
                name="title"
                required
                defaultValue={props.blog?.title}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="description" className="col-sm-3 col-form-label">
              Description:
            </Label>
            <Col sm={9}>
              <Input
                type="textarea"
                className="form-control"
                id="description"
                name="description"
                maxLength={160}
                rows={5}
                required
                defaultValue={props.blog?.description}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="content" className="col-sm-3 col-form-label">
              Content:
            </Label>
            <Col sm={9}>
              <Editor
                onChange={editor_updated}
                defaultValue={props.blog?.content ?? ''}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="tag" className="col-sm-3 col-form-label">
              Tags:
            </Label>
            <Col sm={9}>
              <MultiSelect
                options={tags}
                value={selectedTags}
                onChange={tags_changed}
                labelledBy="tags"
              />
            </Col>
          </Row>
          <div className="modal-footer">
            <input type="submit" value="Submit" className="btn btn-primary" />
          </div>
        </form>
      </div>
    </div>
  )
}
