import React from 'react'

//import components
import { SidebarContent } from '../sidebarContent'

const sidebar = (props: any) => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          <SidebarContent location={{ pathname: window.location.href }} />
        </div>
      </div>
    </React.Fragment>
  )
}

export const Sidebar = sidebar
