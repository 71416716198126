import { withAuthenticator } from '@aws-amplify/ui-react'
import { Tutorial } from '@imlearningteam/imlearning-types'
import React, { useEffect, useState } from 'react'
import { Footer } from '../../components/footer'
import { Header } from '../../components/header'
import { Sidebar } from '../../components/sidebar'
import { deleteData, getData } from '../../utils/server'
import { Table } from '../../components/table'
import { Modal } from 'reactstrap'
import AddTutorialModal from './addTutorialModal'
import { daysUntilReview } from '../../utils/resources'
import { SocialButton } from '../../components/social'

function Tuts() {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [modalTutorial, setModalTutorial] = useState<Tutorial>()
  const [tutorials, setTutorials] = useState<any[]>([])

  useEffect(() => {
    if (!success && !error) {
      getData('/tutorial').then(
        (result: any) => {
          setSuccess(true)
          setTutorials(result)
        },
        (error: any) => {
          setError(error)
        },
      )
    }
  })

  function tog_backdrop(item?: Tutorial) {
    setModalTutorial(undefined)
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()

    if (item) {
      setModalTutorial(item)
    }
  }

  function put_tutorial(tutorial: Tutorial) {
    if (tutorials.some((x) => x.id === tutorial.id)) {
      let item = tutorials.findIndex((x) => x.id === tutorial.id)
      tutorials[item] = tutorial
    } else {
      setTutorials([...tutorials, tutorial])
    }
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }

  function delete_item(id: string) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this Tutorial?') === true) {
      deleteData('/tutorial', id).then(
        (result: any) => {
          setTutorials(
            tutorials.filter(function (tutorial) {
              return tutorial.id !== id
            }),
          )
        },
        (error: any) => {
          setError(error)
        },
      )
    }
  }

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div className="main-content">
          <div className="page-content">
            <h4 className="font-size-18">Tutorials</h4>
            <button
              type="button"
              className="btn btn-success float-end"
              onClick={() => {
                tog_backdrop()
              }}
              data-toggle="modal"
              style={{ marginRight: '50px' }}
            >
              Add New
            </button>
            <Modal
              size="lg"
              isOpen={modal_backdrop}
              toggle={() => {
                tog_backdrop()
              }}
              backdrop={'static'}
              id="staticBackdrop"
            >
              <AddTutorialModal
                setmodal_backdrop={setmodal_backdrop}
                put_tutorial={put_tutorial}
                tutorial={modalTutorial}
              />
            </Modal>
            <Table columns={['🔗', 'Social', 'Title', 'Platform']}>
              {tutorials.map((item: Tutorial, count) => {
                return (
                  <tr
                    key={count}
                    className={
                      daysUntilReview(new Date(item.lastUpdated)) < 10
                        ? 'bg-danger'
                        : ''
                    }
                  >
                    <td>
                      <a
                        href={
                          process.env.REACT_APP_IM_LEARNING_URL +
                          '/tutorial/' +
                          item.slug
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bx bx-link-external"></i>
                      </a>
                    </td>
                    <td>
                      <SocialButton
                        text={`Check out this tutorial on ${item.platform}, "${item.title}"! ${item.description}`}
                        url={
                          item.link?.href
                            ? item.link?.href
                            : `https://imlearning.com/tutorial/${item.slug}`
                        }
                        hashtags="imlearning"
                      />
                    </td>
                    <td>{item.title}</td>
                    <td>{item.platform}</td>
                    <td>{daysUntilReview(new Date(item.lastUpdated))} days</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          tog_backdrop(item)
                        }}
                        data-toggle="modal"
                        style={{ marginRight: '50px' }}
                      >
                        Update
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-light btn-sm"
                        onClick={() => {
                          delete_item(item.id)
                        }}
                        data-toggle="modal"
                      >
                        <i className="bx bx-x"></i>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </Table>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export const Tutorials = withAuthenticator(Tuts, {
  hideSignUp: true,
  loginMechanisms: ['email'],
})
