import React from 'react'

interface TableProps {
  columns: string[]
  children: any
}

export const Table = (props: TableProps) => {
  return (
    <table className="table table-hover">
      <thead>
        <tr>
          {props.columns.map((item, count) => {
            return <th key={count}>{item}</th>
          })}
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
    </table>
  )
}
